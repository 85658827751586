export const portfolioData = [
  {
    title: "Vote by Mail Flowchart",
    description:
      "[Technique] Flowchart to explain voting by mail for OOS students at Georgia Tech",
    url: "https://nique.net/news/2022/10/17/2022-midterm-election-voter-resources/",
    date: "October 14, 2022",
  },
  {
    title: "Rankings",
    description:
      "[Technique] Visualization showing Georgia Tech rankings over time",
    url: "https://nique.net/news/2022/10/10/president-cabrera-reflects-on-institute-rankings/",
    date: "October 7, 2022",
  },
  {
    title: "Collins Dismissal",
    description:
      "[Technique] Graphic summarizing the Collins era of Georgia Tech football",
    url: "https://nique.net/sports/2022/09/26/collins-stansbury-relieved-of-duties/",
    date: "September 30, 2022",
  },
  {
    title: "Economic Impact",
    description:
      "[Technique] Graphic showing Georgia Tech's economic impact on the state",
    url: "https://nique.net/news/2022/08/16/techs-state-wide-economic-impact-grows/",
    date: "August 16, 2022",
  },
  {
    title: "COVID-19 Flowchart",
    description:
      "[Technique] Flowchart explaining new COVID guidelines from GT's Stamps Health Center",
    url: "https://nique.net/news/2022/08/16/institute-changes-covid-19-operations/",
    date: "August 16, 2022",
  },
  {
    title: "Dining",
    description:
      "[Technique] Graphs and story about student use of 'Dining Dollars' campus currency",
    url: "https://nique.net/life/2022/06/06/tech-dining-faces-end-of-the-year-feeding-frenzy/",
    date: "June 6, 2022",
  },
  {
    title: "Admissions",
    description:
      "[Technique] Graphs breaking down the Fall 2023 cohort of first-years",
    url: "https://nique.net/news/2022/04/10/institutes-newest-admission-stats/",
    date: "April 8, 2022",
  },
  {
    title: "Building Names",
    description:
      "[Technique] Graphs and story analyzing building names on campus",
    url: "https://nique.net/news/2022/04/02/technique-analysis-finds-only-five-out-of-82-buildings-and-facilities-on-campus-named-after-women/",
    date: "April 1, 2022",
  },
];

export const positionsData = [
  {
    current: true,
    place: "Graduate Student Government Association ",
    positions: [
      {
        title: "Vice President, Campus Services",
        description: [
          "Responsible for working with the eight departments making up Georgia Tech Campus Services in advocating for improvements and enhancing access for graduate students",
          "Served on advisory committees for each department and hosted regular feedback sessions with graduate students to gather feedback.",
        ],
        startDate: "December 2023",
        endDate: "Present",
      },
    ],
  },
  {
    current: true,
    place: "Blueprint",
    positions: [
      {
        title: "Editor-in-Chief",
        description: [
          "Revived the yearbook at Georgia Tech, which had been in hiatus since 2018",
          "Managed a staff of 20+ in the production of Georgia Tech's official yearbook",
          "Built website with CMS integration for a hybrid yearbook",
          "Designed logo and visual language for branding on web/socials and in print",
        ],
        startDate: "June 2023",
        endDate: "Present",
      },
    ],
  },
  {
    place: "Technique",
    positions: [
      {
        title: "Editor-in-Chief",
        description: [
          "Guided a staff of 30+ in the weekly publishing of Georgia Tech's award-winning student newspaper",
          "Communicated with campus organizations and leadership to ensure campus news was accurately covered",
          "Collaborated with campus leaders, off-campus retailers and vendors to expand footprint, partnerships and outreach",
        ],
        startDate: "February 2023",
        endDate: "May 2023",
      },
      {
        title: "Technology Editor",
        description: [
          "Conducted website improvements and managed a team of 6 in developing a React Native app",
          "Wrote data-driven stories and supported articles with custom graphics",
        ],
        startDate: "May 2021",
        endDate: "February 2023",
      },
    ],
  },
  {
    place: "Residence Hall Association (RHA)",
    positions: [
      {
        title: "Resource Room Officer",
        description: [
          "Managed the Residence Hall Association's Resource Room, which facilitates the sharing of cost-prohibitive equipment needed for events.",
          "Managed inventory, handled customer requests, purchased requested items and facilitated delivery of materials to events via Golf Cart (LSEV)",
        ],
        startDate: "October 2022",
        endDate: "May 2023",
      },
    ],
  },
  {
    place: "Office of Information Technology (OIT)",
    positions: [
      {
        title: "Wreck Tech (Resident Technical Assistant)",
        description: [
          "Worked to support residents of Georgia Tech in their connection issues to enterprise network protocol. Helped residents with their computer hardware and software.",
        ],
        startDate: "August 2022",
        endDate: "December 2022",
      },
    ],
  },
  {
    current: true,
    place: "Office of Undergraduate Admissions",
    positions: [
      {
        title: "Tour Guide",
        description: [
          "Led groups of prospective students and their families around the Georgia Tech campus, providing them with information about life at the Institute and answering questions about the admissions process.",
        ],
        startDate: "January 2022",
        endDate: "Present",
      },
    ],
  },
];

export const employmentData = [
  {
    current: "true",
    place: "The Atlanta Journal Constitution",
    positions: [
      {
        title: "Data Visualization Intern",
        description: [
          "Conducted data collection, analysis, processing and warehousing to assist in data-driven/data-assisted storytelling.",
          "Pioneered the creation of databases to facilitate seamless data querying and enhance internal operations.",
        ],
        startDate: "September 2023",
        endDate: "Present",
      },
    ],
  },
  {
    place: "Civi",
    positions: [
      {
        title: "Software Engineer",
        description: [
          "Spearheaded MVP development, Dockerizing applications for seamless Google Cloud Run deployment.",
          "Configured Cloud SQL instances, revamped landing pages, and managed legacy code.",
          "Sole software engineer, utilizing Python, TypeScript, SQL, Alembic, AWS, GCP, and REST APIs.",
        ],
        startDate: "May 2023",
        endDate: "August 2023",
      },
    ],
  },
  {
    place: "NBCUniversal",
    positions: [
      {
        title: "MediaTech Intern",
        description: [
          "Contributed to the Ad Sales team by developing software solutions within the Operations and Technology department.",
          "Catalyzed development of internal project management tool PACER, fostering an Agile development culture at NBCU.",
          "Leveraged Angular (TypeScript), SCSS, and REST APIs to drive innovation in project execution.",
        ],
        startDate: "June 2022",
        endDate: "August 2022",
      },
    ],
  },
  {
    place: "H2O.ai",
    positions: [
      {
        title: "Intern",
        description: [
          "Engineered an intuitive email dashboard, seamlessly integrating and processing sales data to empower executives in data-driven decision-making.",
          "Automated the generation and dispatching of emails by deploying the solution within a Docker Container.",
          "Utilized Python3 (Pandas), Jinja2, HTML, SQL, Docker, Docker Compose, and REST APIs to execute the project with precision.",
        ],
        startDate: "May 2021",
        endDate: "August 2021",
      },
    ],
  },
];
